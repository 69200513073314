exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advertising-guidelines-js": () => import("./../../../src/pages/advertising-guidelines.js" /* webpackChunkName: "component---src-pages-advertising-guidelines-js" */),
  "component---src-pages-award-winners-js": () => import("./../../../src/pages/award-winners.js" /* webpackChunkName: "component---src-pages-award-winners-js" */),
  "component---src-pages-awards-2023-js": () => import("./../../../src/pages/awards-2023.js" /* webpackChunkName: "component---src-pages-awards-2023-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-brand-director-js": () => import("./../../../src/pages/brand-director.js" /* webpackChunkName: "component---src-pages-brand-director-js" */),
  "component---src-pages-coming-fall-2024-js": () => import("./../../../src/pages/coming-fall-2024.js" /* webpackChunkName: "component---src-pages-coming-fall-2024-js" */),
  "component---src-pages-commb-news-js": () => import("./../../../src/pages/commb-news.js" /* webpackChunkName: "component---src-pages-commb-news-js" */),
  "component---src-pages-commb-newsletter-js": () => import("./../../../src/pages/commb-newsletter.js" /* webpackChunkName: "component---src-pages-commb-newsletter-js" */),
  "component---src-pages-commb-studies-js": () => import("./../../../src/pages/commb-studies.js" /* webpackChunkName: "component---src-pages-commb-studies-js" */),
  "component---src-pages-committees-js": () => import("./../../../src/pages/committees.js" /* webpackChunkName: "component---src-pages-committees-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-creative-centre-js": () => import("./../../../src/pages/creative_centre.js" /* webpackChunkName: "component---src-pages-creative-centre-js" */),
  "component---src-pages-data-report-webinars-js": () => import("./../../../src/pages/data-report-webinars.js" /* webpackChunkName: "component---src-pages-data-report-webinars-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-js": () => import("./../../../src/pages/industry.js" /* webpackChunkName: "component---src-pages-industry-js" */),
  "component---src-pages-industry-qas-js": () => import("./../../../src/pages/industry-qas.js" /* webpackChunkName: "component---src-pages-industry-qas-js" */),
  "component---src-pages-infographics-js": () => import("./../../../src/pages/infographics.js" /* webpackChunkName: "component---src-pages-infographics-js" */),
  "component---src-pages-insights-reports-js": () => import("./../../../src/pages/insights-reports.js" /* webpackChunkName: "component---src-pages-insights-reports-js" */),
  "component---src-pages-jobs-brand-director-js": () => import("./../../../src/pages/jobs/brand-director.js" /* webpackChunkName: "component---src-pages-jobs-brand-director-js" */),
  "component---src-pages-jobs-components-hero-js": () => import("./../../../src/pages/jobs/components/hero.js" /* webpackChunkName: "component---src-pages-jobs-components-hero-js" */),
  "component---src-pages-measurement-js": () => import("./../../../src/pages/measurement.js" /* webpackChunkName: "component---src-pages-measurement-js" */),
  "component---src-pages-members-zone-js": () => import("./../../../src/pages/members-zone.js" /* webpackChunkName: "component---src-pages-members-zone-js" */),
  "component---src-pages-membership-js": () => import("./../../../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-methodologies-measurement-principles-js": () => import("./../../../src/pages/methodologies/measurement-principles.js" /* webpackChunkName: "component---src-pages-methodologies-measurement-principles-js" */),
  "component---src-pages-mz-cdr-mz-cdr-video-tutorials-js": () => import("./../../../src/pages/mz-cdr/mz-cdr-video-tutorials.js" /* webpackChunkName: "component---src-pages-mz-cdr-mz-cdr-video-tutorials-js" */),
  "component---src-pages-mz-navigator-rf-js": () => import("./../../../src/pages/mz-navigator-rf.js" /* webpackChunkName: "component---src-pages-mz-navigator-rf-js" */),
  "component---src-pages-mz-navigator-rf-mz-nav-print-tutorials-js": () => import("./../../../src/pages/mz-navigator-rf/mz-nav-print-tutorials.js" /* webpackChunkName: "component---src-pages-mz-navigator-rf-mz-nav-print-tutorials-js" */),
  "component---src-pages-mz-navigator-rf-mz-nav-video-tutorials-js": () => import("./../../../src/pages/mz-navigator-rf/mz-nav-video-tutorials.js" /* webpackChunkName: "component---src-pages-mz-navigator-rf-mz-nav-video-tutorials-js" */),
  "component---src-pages-mz-rapport-de-donnees-commb-mz-cdr-les-didacticiels-video-js": () => import("./../../../src/pages/mz-rapport-de-donnees-commb/mz-cdr-les-didacticiels-video.js" /* webpackChunkName: "component---src-pages-mz-rapport-de-donnees-commb-mz-cdr-les-didacticiels-video-js" */),
  "component---src-pages-navigator-rf-mz-nav-print-tutorials-js": () => import("./../../../src/pages/navigator-rf/mz-nav-print-tutorials.js" /* webpackChunkName: "component---src-pages-navigator-rf-mz-nav-print-tutorials-js" */),
  "component---src-pages-navigator-rf-reach-frequency-model-js": () => import("./../../../src/pages/navigator-rf/reach-frequency-model.js" /* webpackChunkName: "component---src-pages-navigator-rf-reach-frequency-model-js" */),
  "component---src-pages-navigator-webinars-js": () => import("./../../../src/pages/navigator-webinars.js" /* webpackChunkName: "component---src-pages-navigator-webinars-js" */),
  "component---src-pages-past-commb-talks-js": () => import("./../../../src/pages/past-commb-talks.js" /* webpackChunkName: "component---src-pages-past-commb-talks-js" */),
  "component---src-pages-past-talks-js": () => import("./../../../src/pages/past-talks.js" /* webpackChunkName: "component---src-pages-past-talks-js" */),
  "component---src-pages-planning-tools-js": () => import("./../../../src/pages/planning-tools.js" /* webpackChunkName: "component---src-pages-planning-tools-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-provincial-guidelines-js": () => import("./../../../src/pages/provincial-guidelines.js" /* webpackChunkName: "component---src-pages-provincial-guidelines-js" */),
  "component---src-pages-resource-centre-contentful-resource-centre-slug-js": () => import("./../../../src/pages/resource-centre/{ContentfulResourceCentre.slug}.js" /* webpackChunkName: "component---src-pages-resource-centre-contentful-resource-centre-slug-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-software-access-js": () => import("./../../../src/pages/software-access.js" /* webpackChunkName: "component---src-pages-software-access-js" */),
  "component---src-pages-talks-2-js": () => import("./../../../src/pages/talks2.js" /* webpackChunkName: "component---src-pages-talks-2-js" */),
  "component---src-pages-talks-js": () => import("./../../../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-pages-training-webinars-js": () => import("./../../../src/pages/training-webinars.js" /* webpackChunkName: "component---src-pages-training-webinars-js" */),
  "component---src-templates-casestudies-template-js": () => import("./../../../src/templates/casestudies-template.js" /* webpackChunkName: "component---src-templates-casestudies-template-js" */),
  "component---src-templates-creative-library-template-js": () => import("./../../../src/templates/creative_library-template.js" /* webpackChunkName: "component---src-templates-creative-library-template-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-obie-awards-template-js": () => import("./../../../src/templates/obie-awards-template.js" /* webpackChunkName: "component---src-templates-obie-awards-template-js" */)
}

